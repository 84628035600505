// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Icon$DraftbitPersonaAvatarGenerator from "./Icon.bs.js";

function Wordmark(Props) {
  return React.createElement("a", {
              title: "Robust native front-end apps with usable code by Draftbit",
              href: "https://draftbit.com"
            }, React.createElement(Icon$DraftbitPersonaAvatarGenerator.make, {
                  name: "wordmark"
                }));
}

var make = Wordmark;

export {
  make ,
  
}
/* react Not a pure module */
