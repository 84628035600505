// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Modal$DraftbitPersonaAvatarGenerator from "./Modal.bs.js";
import * as Button$DraftbitPersonaAvatarGenerator from "./Button.bs.js";
import * as IconLink$DraftbitPersonaAvatarGenerator from "./IconLink.bs.js";
import * as Wordmark$DraftbitPersonaAvatarGenerator from "./Wordmark.bs.js";
import * as AvatarGenerator$DraftbitPersonaAvatarGenerator from "./AvatarGenerator.bs.js";

function App(Props) {
  var showModal = Props.showModal;
  var onToggleModal = Props.onToggleModal;
  var onExport = Props.onExport;
  var onChange = Props.onChange;
  var randomize = Props.randomize;
  var styles = Props.styles;
  var config = Props.config;
  var settings = [
    {
      id: /* Skin */925778589,
      label: "SKIN",
      colors: config.skinColors,
      styles: config.skinStyles,
      selectedColor: styles.skinColor,
      selectedStyle: styles.skin
    },
    {
      id: /* Hair */803296066,
      label: "HAIR",
      colors: config.hairColors,
      styles: config.hairStyles,
      selectedColor: styles.hairColor,
      selectedStyle: styles.hair
    },
    {
      id: /* FacialHair */-697605842,
      label: "FACIAL HAIR",
      colors: config.facialHairColors,
      styles: config.facialHairStyles,
      selectedColor: styles.facialHairColor,
      selectedStyle: styles.facialHair
    },
    {
      id: /* Body */737453762,
      label: "BODY",
      colors: config.bodyColors,
      styles: config.bodyStyles,
      selectedColor: styles.bodyColor,
      selectedStyle: styles.body
    },
    {
      id: /* Eyes */771219970,
      label: "EYES",
      colors: config.disabledColors,
      styles: config.eyeStyles,
      selectedColor: "000000",
      selectedStyle: styles.eyes
    },
    {
      id: /* Mouth */529696487,
      label: "MOUTH",
      colors: config.disabledColors,
      styles: config.mouthStyles,
      selectedColor: "000000",
      selectedStyle: styles.mouth
    },
    {
      id: /* Nose */870531891,
      label: "NOSE",
      colors: config.disabledColors,
      styles: config.noseStyles,
      selectedColor: styles.skinColor,
      selectedStyle: styles.nose
    },
    {
      id: /* Background */483301390,
      label: "BACKGROUND",
      colors: config.bgColors,
      styles: config.bgStyles,
      selectedColor: styles.bgColor,
      selectedStyle: "Background"
    }
  ];
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "body-bg-left"
                }), React.createElement("div", {
                  className: "body-bg-right"
                }), React.createElement("header", {
                  className: "Layout-header"
                }, React.createElement("div", {
                      className: "Layout-left"
                    }, React.createElement(Wordmark$DraftbitPersonaAvatarGenerator.make, { }), React.createElement("h1", {
                          className: "Text-title"
                        }, "Personas"), React.createElement("span", {
                          className: "Text-subtitle"
                        }, "Avatar Generator")), React.createElement("div", {
                      className: "Layout-right"
                    }, React.createElement("h2", {
                          className: "Text-description"
                        }, "A playful avatar generator for the modern age."))), React.createElement("main", {
                  className: "Layout-main"
                }, React.createElement(AvatarGenerator$DraftbitPersonaAvatarGenerator.make, {
                      randomize: randomize,
                      settings: settings,
                      onChange: onChange,
                      onExport: onExport
                    })), React.createElement("footer", {
                  className: "Layout-footer"
                }, React.createElement("div", {
                      className: "Layout-left"
                    }, React.createElement("span", {
                          className: "Text-overline"
                        }, "Built by"), React.createElement("br", undefined), React.createElement(Wordmark$DraftbitPersonaAvatarGenerator.make, { })), React.createElement("div", {
                      className: "Layout-right"
                    }, React.createElement("span", {
                          className: "Text-marketing mb-2"
                        }, "Visually build robust, native mobile apps with clean, reusable code."), React.createElement("br", undefined), React.createElement(IconLink$DraftbitPersonaAvatarGenerator.make, {
                          title: "Build fobust native front-end apps with usable code using Draftbit",
                          label: "draftbit.com",
                          href: "https://draftbit.com",
                          icon: "arrow"
                        }))), React.createElement("div", {
                  style: {
                    marginBottom: "36px",
                    textAlign: "center"
                  }
                }, React.createElement(Button$DraftbitPersonaAvatarGenerator.make, {
                      label: "View on Github",
                      href: "https://github.com/draftbit/avatar-generator"
                    })), React.createElement(Modal$DraftbitPersonaAvatarGenerator.make, {
                  visible: showModal,
                  onToggle: onToggleModal
                }));
}

var make = App;

export {
  make ,
  
}
/* react Not a pure module */
