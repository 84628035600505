// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

((require('./Button.css')));

function Button(Props) {
  var style = Props.style;
  var label = Props.label;
  var href = Props.href;
  var tmp = {
    className: "Button Button--github",
    href: href
  };
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  return React.createElement("a", tmp, React.createElement("img", {
                  className: "Button-icon",
                  height: "24",
                  src: "/images/github.svg",
                  width: "24"
                }), React.createElement("span", {
                  className: "Button-label"
                }, label));
}

var make = Button;

export {
  make ,
  
}
/*  Not a pure module */
