// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Caml_array from "bs-platform/lib/es6/caml_array.js";
import * as SvgLoader$DraftbitPersonaAvatarGenerator from "./SvgLoader.bs.js";
import * as ColorSwatch$DraftbitPersonaAvatarGenerator from "./ColorSwatch.bs.js";

((require('./Styler.css')));

function Styler(Props) {
  var id = Props.id;
  var label = Props.label;
  var colors = Props.colors;
  var styles = Props.styles;
  var selectedColor = Props.selectedColor;
  var selectedStyle = Props.selectedStyle;
  var onSelectColor = Props.onSelectColor;
  var onSelectStyle = Props.onSelectStyle;
  var match = React.useReducer((function (state, action) {
          if (action) {
            var inc = state.index > 0 ? 1 : (-styles.length | 0) + 1 | 0;
            var index = state.index - inc | 0;
            var style = Caml_array.caml_array_get(styles, index);
            Curry._1(onSelectStyle, "" + (String(style) + ""));
            return {
                    index: index
                  };
          }
          var inc$1 = (styles.length - 1 | 0) > state.index ? 1 : (-styles.length | 0) + 1 | 0;
          var index$1 = state.index + inc$1 | 0;
          var style$1 = Caml_array.caml_array_get(styles, index$1);
          Curry._1(onSelectStyle, "" + (String(style$1) + ""));
          return {
                  index: index$1
                };
        }), {
        index: 0
      });
  var dispatch = match[1];
  var colorSwatches = id !== 529696487 && id !== 771219970 && id !== 870531891 ? Belt_Array.map(colors, (function (color) {
            return React.createElement(ColorSwatch$DraftbitPersonaAvatarGenerator.make, {
                        value: color,
                        selected: color === selectedColor,
                        disabled: color === "#EEEFF5",
                        onSelect: Curry.__1(onSelectColor),
                        key: color
                      });
          })) : null;
  var image = React.createElement(SvgLoader$DraftbitPersonaAvatarGenerator.make, {
        name: selectedStyle,
        fill: "#" + selectedColor
      });
  var showLeftArrow = styles.length > 1 ? React.createElement("button", {
          className: "Styler-btn",
          onClick: (function (param) {
              return Curry._1(dispatch, /* Decrement */1);
            })
        }, React.createElement("img", {
              className: "Styler-arrow",
              src: "/images/arrow.svg"
            })) : React.createElement("div", undefined);
  var showRightArrow = styles.length > 1 ? React.createElement("button", {
          className: "Styler-btn",
          onClick: (function (param) {
              return Curry._1(dispatch, /* Increment */0);
            })
        }, React.createElement("img", {
              className: "Styler-arrow Styler-arrow--right",
              src: "/images/arrow.svg"
            })) : React.createElement("div", undefined);
  var showImage = id !== 483301390 ? React.createElement("div", {
          className: " Styler-model svg-" + (String(id) + " ")
        }, image) : null;
  return React.createElement("div", {
              className: "Styler-container"
            }, React.createElement("span", {
                  className: "Styler-label"
                }, label), React.createElement("div", {
                  className: "Styler-picker"
                }, showLeftArrow, showImage, showRightArrow), React.createElement("div", {
                  className: "Styler-colors"
                }, colorSwatches));
}

var make = Styler;

export {
  make ,
  
}
/*  Not a pure module */
