// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Icon$DraftbitPersonaAvatarGenerator from "./Icon.bs.js";

((require('./IconLink.css')));

function IconLink(Props) {
  var rel = Props.rel;
  var title = Props.title;
  var label = Props.label;
  var href = Props.href;
  var icon = Props.icon;
  var tmp = {
    className: "Text-link",
    href: href
  };
  if (title !== undefined) {
    tmp.title = Caml_option.valFromOption(title);
  }
  if (rel !== undefined) {
    tmp.rel = Caml_option.valFromOption(rel);
  }
  return React.createElement("span", {
              className: "IconLink-container"
            }, React.createElement("a", tmp, label, React.createElement(Icon$DraftbitPersonaAvatarGenerator.make, {
                      name: icon
                    })));
}

var make = IconLink;

export {
  make ,
  
}
/*  Not a pure module */
