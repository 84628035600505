// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Js_mapperRt from "bs-platform/lib/es6/js_mapperRt.js";

var jsMapperConstantArray = [
  /* tuple */[
    -697605842,
    "FacialHair"
  ],
  /* tuple */[
    483301390,
    "Background"
  ],
  /* tuple */[
    529696487,
    "Mouth"
  ],
  /* tuple */[
    737453762,
    "Body"
  ],
  /* tuple */[
    771219970,
    "Eyes"
  ],
  /* tuple */[
    803296066,
    "Hair"
  ],
  /* tuple */[
    803493184,
    "Head"
  ],
  /* tuple */[
    870531891,
    "Nose"
  ],
  /* tuple */[
    925778589,
    "Skin"
  ]
];

function idToJs(param) {
  return Js_mapperRt.binarySearch(9, param, jsMapperConstantArray);
}

function idFromJs(param) {
  return Js_mapperRt.revSearch(9, jsMapperConstantArray, param);
}

export {
  idToJs ,
  idFromJs ,
  
}
/* No side effect */
