// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";

((require('./ColorSwatch.css')));

function ColorSwatch(Props) {
  var value = Props.value;
  var selected = Props.selected;
  var disabledOpt = Props.disabled;
  var onSelect = Props.onSelect;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  if (disabled) {
    return React.createElement("div", {
                className: "ColorSwatch-disabled"
              });
  } else {
    return React.createElement("button", {
                className: "ColorSwatch",
                style: {
                  backgroundColor: "#" + (String(value) + "")
                },
                onClick: (function (param) {
                    return Curry._1(onSelect, value);
                  })
              }, selected ? React.createElement("img", {
                      height: "20",
                      src: "/images/checkmark.svg",
                      width: "20"
                    }) : null);
  }
}

var make = ColorSwatch;

export {
  make ,
  
}
/*  Not a pure module */
